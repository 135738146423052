<template>
  <div>
    <v-dialog v-model='isEditStore' persistent max-width='500'>
      <v-card>
        <v-card-title>
          แก้ไขคลังสินค้า
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formEditStore' @submit.prevent='updateStore'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='store_code' filled dense
                            label='รหัสคลังสินค้า'
                            placeholder='รหัสคลังสินค้า'
                            disabled
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='store_name' filled dense
                            label='ชื่อคลังสินค้า'
                            placeholder='ชื่อคลังสินค้า'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isEditStore`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {required} from '@/@core/utils/validation'
import {api} from '@/services/api'
import store from '@/store'
import {mdiPercentOutline} from "@mdi/js";

export default {
  model: {
    prop: 'isEditStore',
    event: 'update:isEditStore',
  },
  props: {
    isEditStore: {
      type: Boolean,
      default: false,
    },
    dataEditStore: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, {root, emit}) {
    const store_name = ref('')
    const store_code = ref('')
    const loading = ref(false)
    const formEditStore = ref(null)


    const updateStore = () => {
      const isFormValid = formEditStore.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.put({
        path: '/manager/store',
        param: props.dataEditStore.store_id,
        body: {
          store_name: store_name.value,
          store_code: store_code.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isEditStore', false)
          emit('onUpdate')
          store_name.value = ''
          store_code.value = ''
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isEditStore, value => {
      if (value) {
        const dataEditLocal = JSON.parse(JSON.stringify(props.dataEditStore))
        loading.value = false
        store_name.value = dataEditLocal.store_name
        store_code.value = dataEditLocal.store_code
      }
    })

    return {
      formEditStore,
      loading,
      store_name,
      store_code,
      mdiPercentOutline,
      required,
      updateStore,
    }
  },

}
</script>

<style scoped>

</style>
