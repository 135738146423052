<template>
  <div>
    <v-dialog v-model='isAddNewStore' persistent max-width='500'>
      <v-card>
        <v-card-title>
          เพิ่มคลังสินค้า
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddNewStore' @submit.prevent='createStore'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='store_code' filled dense
                            label='รหัสคลังสินค้า'
                            placeholder='รหัสคลังสินค้า'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='store_name' filled dense
                            label='ชื่อคลังสินค้า'
                            placeholder='ชื่อคลังสินค้า'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isAddNewStore`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {required} from '@/@core/utils/validation'
import {api} from '@/services/api'
import store from '@/store'
import {mdiPercentOutline} from "@mdi/js";

export default {
  model: {
    prop: 'isAddNewStore',
    event: 'update:isAddNewStore',
  },
  props: {
    isAddNewStore: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {root, emit}) {
    const store_name = ref('')
    const store_code = ref('')
    const loading = ref(false)
    const formAddNewStore = ref(null)


    const createStore = () => {
      const isFormValid = formAddNewStore.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.post({
        path: '/manager/store',
        body: {
          store_name: store_name.value,
          store_code: store_code.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewStore', false)
          emit('onAdd')
          store_name.value = ''
          store_code.value = ''
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isAddNewStore, value => {
      if (value) {
        loading.value = false
        store_name.value = ''
        store_code.value = ''
      }
    })

    return {
      formAddNewStore,
      loading,
      store_name,
      store_code,
      mdiPercentOutline,
      required,
      createStore,
    }
  },

}
</script>

<style scoped>

</style>
