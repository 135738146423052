import {ref, onMounted} from '@vue/composition-api'
import footerDataTables from '@/fake-db/footerDataTables.json'
import {api} from '@/services/api'

export default function useStore() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'store_id',
            width: '50',
        },
        {
            text: 'รหัส',
            value: 'store_code',
            align: 'center',
        },
        {
            text: 'ชื่อคลังสินค้า/สาขา',
            value: 'store_name',
            width: 200,
        },
        {
            text: 'มูลค่ารวมคลังสินค้า',
            value: 'store_price_total',
            align: 'end',
            width: 200,
        },
        {
            text: 'จำนวนทั้งหมด/ชิ้น',
            value: 'store_balance_total',
            align: 'end',
            width: 200,
        },
        {
            text: 'สถานะ',
            value: 'store_active',
            align: 'center'
        },
        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '200',
            align: 'center fixed',
        },
    ])
    const itemsPerPage = ref(10)
    const status = ref('1')
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const changeStatus = (id) => {
        status.value = id
        getStore()
    }

    const getStore = () => {
        loading.value = true
        api.get({
            path: '/manager/stores',
            param: `?search=${textSearch.value}&active=${status.value}`,
        }).then(({data}) => {
            dataList.value = data
            loading.value = false
        }).catch(error => {
            console.log('error :', error)
            loading.value = false
        })
    }

    onMounted(() => {
        getStore()
    })

    return {
        textSearch,
        headers,
        itemsPerPage,
        isAdd,
        isEdit,
        dataEdit,
        footer,
        isUpdateStatus,
        status,
        dataList,
        loading,
        getStore,
        changeStatus,
    }
}
