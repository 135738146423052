<template>
    <div>
        <v-card>
            <v-card-title class="px-2">
        <span>
          คลังสินค้า
        </span>
                <v-spacer></v-spacer>
                <v-btn color='primary' @click='isAdd=true'>
                    เพิ่มคลังสินค้า
                </v-btn>
            </v-card-title>
            <v-row class='px-2 pb-2'>
                <v-col cols='12' md='6' lg='4'>
                    <v-text-field v-model='textSearch'
                                  filled
                                  @input='getStore'
                                  label='ค้นหาคลังสินค้า'
                                  dense
                                  hide-details
                                  placeholder='คำค้นหา'></v-text-field>
                </v-col>
                <v-col cols='12' md='6' lg='4'>
                    <FilterStatus @selection='changeStatus'/>
                </v-col>
            </v-row>
            <v-data-table
                    :headers='headers'
                    :items='dataList'
                    :items-per-page='itemsPerPage'
                    :loading='loading'
                    no-data-text='ไม่มีข้อมูล !'
                    loading-text='กำลังโหลดข้อมูล...'
                    :footer-props='footer'
                    no-results-text='ไม่มีข้อมูล !'
                    class='elevation-1'
            >
                <template #[`item.store_id`]='{ index }'>
                    {{ index + 1 }}
                </template>
                <template #[`item.store_code`]='{ item }'>
                    <router-link
                            class="font-weight-medium text-decoration-none"
                            :to="{ name: 'manager-store-detail', params: { id: item.store_id } }"
                    >
                        {{ item.store_code }}
                    </router-link>
                </template>
                <template #[`item.store_active`]='{ item }'>
                    <StatusBlock :status='String(item.store_active)'/>
                </template>
                <template #[`item.actions`]='{ item }'>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    :to="{
                    name: 'manager-store-detail',
                    params: { id: item.store_id }
                  }"
                                    v-on='on'
                            >
                                <v-icon>{{ mdiImport }}</v-icon>
                            </v-btn>
                        </template>
                        <span>นำเข้าสินค้า</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    @click='dataEdit = item;isEdit = true'
                                    v-on='on'
                            >
                                <v-icon>{{ mdiPencilOutline }}</v-icon>
                            </v-btn>
                        </template>
                        <span>แก้ไข</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn
                                    color='primary'
                                    icon
                                    v-bind='attrs'
                                    @click='dataEdit = item;isUpdateStatus = true'
                                    v-on='on'
                            >
                                <v-icon>{{ item.store_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ item.store_active == 1 ? `ระงับ` : `ปกติ` }}</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
        <AddNewStore v-model='isAdd' @onAdd='getStore'/>
        <EditStore v-model='isEdit' :data-edit-store='dataEdit' @onUpdate='getStore'/>
        <SuspendStore v-model='isUpdateStatus' :data-update='dataEdit' @onUpdate='getStore'/>
    </div>
</template>

<script>
import FilterStatus from '@/components/FilterStatus.vue'
import useStore from './useStore'
import StatusBlock from '@/components/StatusBlock.vue'
import { mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiImport } from '@mdi/js'
import AddNewStore from '@/views/manager/store/AddNewStore.vue'
import EditStore from "@/views/manager/store/EditStore";
import SuspendStore from "@/views/manager/store/SuspendStore";

export default {
    props: {},
    components: {
        SuspendStore,
        EditStore,
        FilterStatus,
        AddNewStore,
        StatusBlock,
    },
    setup(_, { root, emit }) {
        return {
            ...useStore(),
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiCheck,
            mdiImport,
        }
    },

}
</script>

<style scoped>

</style>
